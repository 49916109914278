<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/Page-header";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";

/**
 * Starter component
 */
export default {
  head () {
    return {
      title: `${this.title} | Minton - Nuxtjs Responsive Admin Dashboard Template`,
    };
  },
  data () {
    return {
      title: "分頁新聞版位設定",
      items: [
        {
          text: "行銷版位管理",
          href: "/",
        },
        {
          text: "分頁新聞版位設定",
          active: true,
        }
      ],
      search_data: {
        id: '',
        name: '',
        setting: null,
        editer: null,
        dateStart: null,
        dateEnd: null,
      },
      currentDateRange: [],
      visible: true,
      // 人員清單列表
      editerOptions: [],

      tableData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: false,
      fields: [
        {
          key: "id",
          label: "組件編號",
          sortable: true,
          class: 'width-auto center'
        },
        {
          key: "name",
          label: "組件名稱",
          sortable: false,
          class: 'width-auto center'
        },
        {
          key: "setting_page",
          label: "分頁數量",
          sortable: false,
          class: 'width-auto center'
        },
        {
          key: "editer",
          label: "更新人員",
          sortable: false,
          class: 'width-auto center'
        },
        {
          key: "lastedit_time",
          label: "最近更新時間",
          sortable: true,
          class: 'width-auto center'
        },
        {
          key: "setting",
          label: "顯示頁面",
          sortable: false,
          class: 'width-auto center'
        },
        {
          key: "action",
          label: "操作",
          sortable: false,
          class: 'width-auto center',
          stickyColumn: true
        }
      ],
    };
  },
  computed: {
    rows () {
      return this.tableData.length;
    },
    settingOptions () {
      return this.$root.newsTypeList.map(_type => ({
        id: _type.id,
        region_type: 2,
        name: `新聞內頁: ${_type.name}`
      })).concat({
        id: 'vote',
        region_type: 1,
        name: '投票專區'
      })
    }
  },
  mounted () {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.searchManagerList('')
    this.reloadNewsTypeList()
  },
  methods: {
    // 搜尋人員列表
    searchManagerList (_keyword) {
      let vm = this

      this.$root.apis.getManagersList({
        name: _keyword
      }, function (_response) {
        let items = _response.body.data.items
        items.forEach(function (_item) {
          vm.editerOptions.push(_item)
        })
      })
    },
    onDateSelect (_dateRange) {
      this.currentDateRange = _dateRange
      if (_dateRange.length > 0) {
        this.search_data.dateStart = this.$root.common.getNowTime('date', _dateRange[0])
        this.search_data.dateEnd = this.$root.common.getNowTime('date', _dateRange[1])
      }
    },
    onResetSearch () {
      this.search_data = {
        id: '',
        name: '',
        setting: null,
        editer: null,
        dateStart: null,
        dateEnd: null,
      }
      this.currentDateRange = []
    },
    reloadNewsTypeList () {
      this.$refs.placementTable.refresh()
    },
    searchMarketingNewsTypeList (_table, _callback) {
      const vm = this

      const searchParam = {
        page: this.currentPage || 1,
        per_page: this.perPage || 10,
        id: this.search_data?.id || '',
        name: this.search_data?.name || '',
        region_type: this.search_data?.setting?.region_type || null,
        newstype_id: this.search_data?.setting?.region_type === 1 ? '' : this.search_data?.setting?.id || null,
        editer: this.search_data?.editer?.id || null,
        date_start: this.search_data?.dateStart || null,
        date_end: this.search_data?.dateEnd || null,
      }

      this.$root.apis.getMarketingNewsTypeList(searchParam,
        function (_response) {
          const result = _response.body.data
          vm.totalRows = result.total
          vm.currentPage = result.page
          vm.perPage = result.per_page
          if (typeof _callback === 'function') {
            vm.tableData = result.comments
            _callback(vm.tableData)
          }
        }, function (_error) {
          console.error(_error.body.data)
          if (typeof _callback === 'function') {
            _callback([])
          }
        })
    },
    /**
     * Search the table data with search input
     */
    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onRemovePlacement (_id) {
      console.log('remove', _id)
      const vm = this
      this.$root.common.confirmAction("刪除組件", '確定要刪除此組件？', "刪除", "再想一下",
        function (_result) {
          vm.$root.apis.removePlacementSetting(_id,
            function (_response) {
              vm.$root.common.showSingleNotify('刪除成功！')
              vm.reloadNewsTypeList()
            },
            function (_error) {
              vm.$root.common.showErrorDialog(_error.body.data)
            })
        }
      )
    },
    onCreatePlacementSetting () {
      const vm = this
      this.$root.apis.createPlacementSetting(
        function (_response) {
          const newSettingId = _response.body.data
          vm.$router.push(`/placement/setting/${newSettingId}`)
        },
        function (_error) {
          console.error(_error.body.data)
        }
      )
    }
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
  }
};
</script>

<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  .row
    .col-lg-12
      //- 搜尋bar
      b-card.mb-1(no-body)
        b-card-header(header-tag="header", role="tab")
          .header-row
            .header-top
              .header-title 篩選查詢 
                i.fe-settings.ml-2
              a.collapse-turn.mr-2(@click="visible = !visible", href="javascript: void(0);")
                span(v-if="visible") 收起 
                  i.fe-chevron-up
                span(v-else) 展開 
                  i.fe-chevron-down
            b-button.action-btn.ml-lg-1.width-lg(variant="primary" @click="reloadNewsTypeList") 查詢
            b-button.action-btn.ml-lg-1.width-lg(variant="secondary" @click="onResetSearch") 清除
        b-collapse#customaccordion-1(v-model="visible", accordion="accordion", role="tabpanel")
          b-card-body
            form.form-horizontal(role="form")
              .row
                //- ID
                .col-12.col-lg-4
                  .form-group.mb-3
                    label 組件編號
                    br
                    input.form-control(v-model="search_data.id", type="text", placeholder="請輸入組件編號")
                .col-12.col-lg-4
                  .form-group.mb-3
                    label 組件名稱
                    br
                    input.form-control(v-model="search_data.name", type="text", placeholder="")
                .col-12.col-lg-4
                  .form-group.mb-3
                    label 顯示頁面
                    br
                    multiselect(
                      v-model="search_data.setting", 
                      :options="settingOptions", 
                      placeholder="請選擇顯示頁面", 
                      track-by="id",
                      label="name",
                      select-label=""
                    )
              .row
                .col-12.col-lg-4
                  .form-group.mb-3
                    label 更新人員
                    br
                    multiselect(
                      v-model="search_data.editer", 
                      :options="editerOptions", 
                      placeholder="請輸入選擇人員", 
                      :multiple="false",
                      track-by="id"
                      label="name",
                      select-label=""
                    )
                .col-12.col-lg-4
                  .form-group.mb-3
                    label 更新日期
                    br
                    date-picker(
                      :value="currentDateRange"
                      @input="onDateSelect"
                      range, 
                      append-to-body, 
                      lang="en",
                      confirm,
                      format="YYYY-MM-DD",
                      placeholder="請選擇日期區間"
                    )
      // 表格
      .card
        .card-body
          .header-row.mb-3
            .header-title 組件列表
            b-button.ml-1.width-lg(variant="primary" @click="onCreatePlacementSetting") 新增
          // Table
          .table-responsive.mb-0
            b-table(
              ref="placementTable"
              :items="searchMarketingNewsTypeList"
              :fields="fields"
              hover
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            )

              template(v-slot:cell(id)="row")
                span {{ row.item.id }}

              template(v-slot:cell(name)="row")
                span {{ row.item.name || '-' }}

              template(v-slot:cell(setting_page)="row")
                span {{ row.item.setting?.length || 0 }}

              template(v-slot:cell(editer)="row")
                span {{ row.item.editer?.name || '-' }}

              template(v-slot:cell(lastedit_time)="row")
                span {{ row.item.lastedit_time || '-' }}

              template(v-slot:cell(setting)="row")
                span(v-if="!row.item.setting.length") -
                b-button.mr-1.py-0.px-1(
                  variant="outline-primary",
                  size="sm",
                  pill,
                  :key="item.newstype_id"
                  v-for="item in row.item.setting"
                )
                  span(v-if="item.region_type === 1") 投票專區
                  span(v-else) 新聞內頁: {{ $root.newsTypeList.find(_type => _type.id === item.newstype_id )?.name || '' }}

              //- action
              template(v-slot:cell(action)="row")
                .action-flex
                  b-button.table-btn.mr-2(
                    :to="'/placement/setting/' + row.item.id"
                  )
                    | 編輯
                  b-button.table-btn(
                    @click="onRemovePlacement(row.item.id)"
                  )
                    | 刪除

          .row.mb-md-2
            //- .col-6
              div(id="tickets-table_length", class="dataTables_length")
                label.d-inline-flex.align-items-center
                  | Show
                  b-form-select.ml-1.mr-1(v-model="perPage", size="sm", :options="pageOptions")
                  | entries
            .col
              div(class="dataTables_paginate paging_simple_numbers float-right")
                ul.pagination.pagination-rounded.mb-0
                  // pagination
                  b-pagination(v-model="currentPage", :total-rows="rows", :per-page="perPage")

  
</template>
<style lang="sass" scoped>
.card-header
  background: #fff
.label-select
  border: none
  padding: 0
  height: auto
  line-height: 1
  font-weight: 600
.header-row
  display: flex
  align-items: center
  .header-top
    flex-grow: 1
    display: flex
    justify-content: space-between
    align-items: center
  .header-title
    margin: 0
    flex: 1
  
::v-deep .action-flex
  display: flex
  justify-content: center

::v-deep .table-btn
  text-decoration: underline
  color: #0073b8
  background: none
  border: none
  padding: 0
  &.delete
    color: #333333
::v-deep  .b-table-sticky-column:last-child
  right: 0
  position: sticky !important
::v-deep .width-auto
  min-width: auto
  white-space: nowrap
  vertical-align: middle
  &.center
    text-align: center
::v-deep .table-btn
  text-decoration: underline
  color: #0073b8
  background: none
  border: none
  padding: 0

.action-btn
  margin-left: 6px

@media  (max-width: 992px)
  .header-row
    flex-direction: column
    align-items: flex-start
    .header-title
      margin-bottom: 1rem
    .header-top
      width: 100%
      justify-self: space-between
      margin-bottom: 1rem
    .action-btn
      width: 100%
      margin: 0 0 6px 0
</style>
